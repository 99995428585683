.App {
  text-align: center;
}

@media (max-width: 1180px) {
  .logo {
    width: 100%;
  }
  .background {
    background-size: cover !important;
  }
}

@media (max-width: 788px) {
  .logo {
    width: 100%;
  }

  .background {
    background-size: cover !important;
  }
}
